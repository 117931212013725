import React from 'react';
import './Skills.css';

const Skills = () => {
  const skillGroups = {
    platforms: ['Linux', 'Docker', 'Kubernetes/OpenShift', 'Azure'],
    programming: ['Python', 'Java', 'JavaScript',  'HTML/CSS','SQL'],
    knowledgeAreas: ['Data Analysis', 'Data Science', ' Machine Learning' , 'Cloud Computing'],
    extra: ['Agile Methodologies', 'Git', 'CI/CD', 'Version Control'],
  };

  return (
    <div className="skills-container">
      <div className="skills-grid">
        {Object.entries(skillGroups).map(([group, skills]) => (
          <div key={group} className="skill-group">
            <h2>{group.charAt(0).toUpperCase() + group.slice(1)}</h2>
            <ul>
              {skills.map(skill => (
                <li key={skill}>{skill}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;