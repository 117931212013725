import React from 'react';

const Footer = () => {
    return (
        <footer class="footer">
        <div class="container grid-list">
    
          <div class="footer-brand">
    
            <a href="#" class="logo">
              <img src="images/logo-dark.png" width="74" height="24" alt="Hearbless home"/>
            </a>
    
            <p class="footer-text">
              &copy; 2024 HearBless <br/> All rights reserved.
            </p>
    
            <ul class="social-list">
    
            </ul>
    
          </div>
    
          <ul class="footer-list">    
            <li>
            <p class="h4 footer-list-title">About Us</p>
            </li>

            <li>
            <a href="/about" class="footer-link">Company</a>
            </li>

            <li>
            <a href="/cases" class="footer-link">Projects</a>
            </li>    
            <li>
            <a href="/contact" class="footer-link">Contact</a>
            </li>    
          </ul>
    
          <ul class="footer-list">
    
            <li>
              <a href="/terms-of-use" class="footer-link">Terms of Use</a>
            </li>
    
            <li>
              <a href="/privacy-policy" class="footer-link">Privacy Policy</a>
            </li>
    
          </ul>          
    
        </div>
      </footer>
    );
};

export default Footer;