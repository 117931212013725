import React from 'react';
import { ChevronDown } from 'react-ionicons';

const Accordion = ({ title, isActive, onToggle, children }) => {
  return (
    <div className={`accordion-card ${isActive ? 'expanded' : ''}`} data-accordion>
      <h3 className="card-title">
        <button className="accordion-btn" data-accordion-btn onClick={onToggle}>
          <ChevronDown
            color={'#00000'}
            title={""}
            height="15px"
            width="15px"
            aria-hidden="true"
            className="down"
          />
          <span className="span h5">{title}</span>
        </button>
      </h3>
      {isActive && <p className="accordion-content">{children}</p>}
    </div>
  );
};

export default Accordion;
