import React from 'react';
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const ProjectSection = () => {
  const { language } = useLanguage();
  const content = language === 'en' ? enContent : nlContent;

  return (
    <section className="section project" aria-labelledby="project-label">
      <div className="container">
        <p className="section-subtitle" id="project-label"><div dangerouslySetInnerHTML={{ __html: content.projectSection.projectTitle }} /></p>

        <h3 className="h2 section-title">
        <div dangerouslySetInnerHTML={{ __html: content.projectSection.projectDescription }} />
        </h3>

        <ul className="grid-list">
          <ProjectCard
            imageUrl="images/blog-data_graph.jpg"
            title={{ __html: content.projectSection.projectExample.title }}
            description={{ __html: content.projectSection.projectExample.description }}
            date="2024-05-21"
            category="Example"
            case_url="/example"
          />
          
          {/* <ProjectCard
            imageUrl="images/blog-security.jpg"
            title="Nullam id dolor elit id nibh"
            description="Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor."
            date="2022-03-29"
            category="Workspace"
          />

          <ProjectCard
            imageUrl="images/blog-3.jpg"
            title="Ultricies fusce porta elit"
            description="Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor."
            date="2022-02-26"
            category="Meeting"
          /> */}
          
        </ul>
      </div>
    </section>
  );
};

const ProjectCard = ({ imageUrl, title, description, date, category, case_url }) => {
  return (
    <li>
      <div className="project-card">
        <figure className="card-banner img-holder" style={{ width: "auto", height: "auto" }}>
          <img src={imageUrl} width="560" height="350" loading="lazy" alt="Project" className="img-cover" />
        </figure>

        <div className="card-content">
          <h3 className="h3">
            <a href={case_url} className="card-title" dangerouslySetInnerHTML={title} />
          </h3>

          <p className="card-text" dangerouslySetInnerHTML={description} />

          <ul className="card-meta-list">
            <li className="card-meta-item">
              <time className="meta-text" dateTime={date}>{new Date(date).toLocaleDateString()}</time>
            </li>

            <li className="card-meta-item">
              <span className="meta-text">{category}</span>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};
export default ProjectSection;