import React from 'react';
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const Vaardigheden = () => {
  const { language } = useLanguage();
  const content = language === 'en' ? enContent : nlContent;

  return (
    <section className="about" aria-labelledby="about-label">
      <div className="container">
        <div className="about-content">
          <h2 className="h2 section-title">
            <div dangerouslySetInnerHTML={{ __html: content.skills.title }} />
          </h2>
          <div className="about-content">
            <div dangerouslySetInnerHTML={{ __html: content.skills.description }} />
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vaardigheden;
