import React from 'react';
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const AboutHearbless = () => {
    const { language } = useLanguage();
    const content = language === 'en' ? enContent : nlContent;

    return (
    <section className="section hero" aria-label="home">

    <div className="container">
        <div className="hero-content">

        <h2 className="h2 section-title"><div dangerouslySetInnerHTML={{ __html: content.aboutHearbless.title }} /></h2>

        <div className="about-content">
        <div dangerouslySetInnerHTML={{ __html: content.aboutHearbless.description }} />
            </div>
        </div>
    </div>
    </section>
    
    );
};


export default AboutHearbless; 
