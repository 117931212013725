import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavbarToggle = () => {
  const location = useLocation();

  useEffect(() => {
    const navbar = document.querySelector("[data-navbar]");
    const navTogglers = document.querySelectorAll("[data-nav-toggler]");
    const overlay = document.querySelector("[data-overlay]");
    const navLinks = document.querySelectorAll(".navbar-link");

    const toggleNavbar = () => {
      navbar.classList.toggle("active");
      overlay.classList.toggle("active");
      document.body.classList.toggle("nav-active");
    };

    const closeNavbar = () => {
      navbar.classList.remove("active");
      overlay.classList.remove("active");
      document.body.classList.remove("nav-active");
    };

    navTogglers.forEach(toggler => toggler.addEventListener("click", toggleNavbar));
    navLinks.forEach(link => link.addEventListener("click", closeNavbar));

    return () => {
      navTogglers.forEach(toggler => toggler.removeEventListener("click", toggleNavbar));
      navLinks.forEach(link => link.removeEventListener("click", closeNavbar));
    };
  }, [location]); // Re-run the effect when the location changes

  return null;
};

export default NavbarToggle;
