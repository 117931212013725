// App.js

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/homePage/Home';
import ScrollToTop from './utils/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/aboutPage/About';
import Cases from './components/casesPage/Cases';
import Contact from "./components/contactPage/Contact";
import Services from "./components/servicesPage/Services";
import ErrorPage from "./components/ErrorPage";
import { LanguageProvider } from './utils/LanguageContext'; // Import LanguageProvider


import './css/styles.css';

const App = () => {
  return (
    <LanguageProvider> {/* Wrap the entire app with LanguageProvider */}
      <Router>
        <ScrollToTop />      
        <div>        
          <Header />        
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/cases" element={<Cases />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </LanguageProvider>
  );
};

export default App;
