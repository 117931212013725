import React, { useState } from 'react';
import Accordion from '../../utils/Accordion';
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const AboutUs = () => {
  const { language } = useLanguage();
  const content = language === 'en' ? enContent : nlContent;

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="about" aria-labelledby="about-label">
      <div className="container">
        <figure className="about-banner">
          <img src="images/about-banner.jpg" width="800" height="580" loading="lazy" alt="about banner"
            className="w-100" />
        </figure>

        <div className="about-content">
          <p className="section-subtitle" id="about-label" jsonKey="aboutLable">{content.aboutUs.aboutLable}</p>
          <h2 className="h2 section-title">
            {content.aboutUs.aboutText}
          </h2>

          <ul>
            <li className="about-item">
              <Accordion
                title={content.aboutUs.titleCustomerFocused}
                isActive={activeIndex === 0}
                onToggle={() => toggleAccordion(0)}
              >
                <div dangerouslySetInnerHTML={{ __html: content.aboutUs.descriptionCustomerFocused }} />

              </Accordion>
            </li>
            <li className="about-item">
              <Accordion
                title={content.aboutUs.titleAutomation}
                isActive={activeIndex === 1}
                onToggle={() => toggleAccordion(1)}
              >
              
              <div dangerouslySetInnerHTML={{ __html: content.aboutUs.descriptionAutomation }} />
               
              </Accordion>
            </li>
            <li className="about-item">
              <Accordion
                title={content.aboutUs.titleCloud}
                isActive={activeIndex === 2}
                onToggle={() => toggleAccordion(2)}
              >
                <div dangerouslySetInnerHTML={{ __html: content.aboutUs.descriptionCloud }} />
              </Accordion>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
