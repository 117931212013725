import React from 'react';
import '../../css/style.css'; // Import the CSS file
import HeroSection from './HeroSection';
import AboutUs from './AboutUs'
import Business from './Business';
import Feature from './Feature';
import ProjectCarousel from './ProjectCarousel';

const Home = () => {
  return (
    <main>
        <article>
            <div className="section-container">
                <div className="home-container">
                    <div><HeroSection /></div>
                    <div><Business /></div>
                    <div><AboutUs /></div>
                    {/* <div><Feature /></div> */ }
                    <div><ProjectCarousel /></div>
                </div>
            </div>
      </article>
    </main>
  );
};

export default Home;
