import React from 'react';
import '../../css/style.css'; // Import the CSS file
import AboutHearbless from './AboutHearbless'
import AboutMe from './AboutMe'
import Vaardigheden from './Vaardigheden'
import Skills from './Skills'

const Home = () => {
  return (
    <main>
        <article>
            <div className="section-container">
                <div className="home-container">
                <div><AboutHearbless /></div>
                <div><AboutMe /></div>
                <div><Vaardigheden /></div>
                <div><Skills /></div>
                </div>
            </div>
      </article>
    </main>
  );
};

export default Home;
