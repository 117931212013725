import React, { useEffect } from 'react';

const HeaderToggle = () => {
  useEffect(() => {
    const header = document.querySelector("[data-header]");

    const onScroll = () => {
      if (window.scrollY > 100) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return null;
};

export default HeaderToggle;