import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css'; // Import the CSS file
import '../../css/style.css'; // Import the CSS file
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const config = require("../../config/config-test.json");

let headers = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }
  };

const ContactForm = () => {
    const { language } = useLanguage();
    const content = language === 'en' ? enContent : nlContent;

    const [firstName, setFirstName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let error = '';

        if (!firstName) {
            error += 'Please enter your First Name.\n';
        }

        if (!companyName) {
            error += 'Please enter your Last Name.\n';
        }

        if (!validateEmail(email)) {
            error += 'Please enter a valid email address.\n';
        }

        if (description.length > 500) {
            error += 'Description cannot exceed 500 words.\n';
        }

        if (error) {
            setErrorMessage(error);
            return;
        }

        try {
            // Send data to the server
            await axios.post(config.EMAILSERVER_URL+"?code="+config.FUNCTION_KEY, {                
                firstName,
                companyName,
                email,
                description
            }, headers );

            // Reset form fields and error message
            setFirstName('');
            setCompanyName('');
            setEmail('');
            setDescription('');
            setErrorMessage('');
            
            // Display success message or redirect user to a thank you page
            alert('Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            setErrorMessage('Error sending email. Please try again later.');
        }
    };

    return (
        <section className="section project" aria-labelledby="project-label">
        <div className="container" >         

            <div className="contact-container">
                <div className="contact-me-container">
                    <div className='subtitle'><div dangerouslySetInnerHTML={{ __html: content.contactSection.title }} /></div>
                    <div className='paragraph'>
                    <div dangerouslySetInnerHTML={{ __html: content.contactSection.description }} />
                    </div>
                </div>
                <div className="contact-form-container">
                    <form id="contact-form" onSubmit={handleSubmit}>
                        {errorMessage && <div className="error">{errorMessage}</div>}
                        <div className="form-group">
                            <label htmlFor="first-name"><div dangerouslySetInnerHTML={{ __html: content.contactForm.name }} /></label>
                            <div>
                                <input
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="company-name"><div dangerouslySetInnerHTML={{ __html: content.contactForm.companyName }} /></label>
                            <div>
                                <input
                                    type="text"
                                    id="company-name"
                                    name="companyName"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email"><div dangerouslySetInnerHTML={{ __html: content.contactForm.email }} /></label>
                            <div>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="description"><div dangerouslySetInnerHTML={{ __html: content.contactForm.description }} />
                            <div className='normal-text'>
                                <div dangerouslySetInnerHTML={{ __html: content.contactForm.maxWords }} />
                            </div>
                            </label>
                            <div>
                                <textarea
                                    type="text"
                                    id="description"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    maxLength="500"
                                    required
                                />
                            </div>
                        </div>
                        <button type="submit"><div dangerouslySetInnerHTML={{ __html: content.contactForm.sendButton }} /></button>
                    </form>
                </div>
            </div>
        </div>
        </section>
    );
};

export default ContactForm;