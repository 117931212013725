import React from 'react';
import '../../css/style.css'; // Import the CSS file
import ContactForm from './ContactForm';

const Contact= () => {
  return (
    <main>
        <article>
            <div className="section-container">
                <div className="home-container">
                        <div><ContactForm /></div>
                </div>
            </div>
      </article>
    </main>
  );
};

export default Contact;
