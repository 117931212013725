import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import NavbarToggle from '../utils/NavbarToggle'; // Import NavbarToggle component
import HeaderToggle from '../utils/HeaderToggle'; // Import HeaderToggle component
import { MenuOutline, LogoInstagram, LogoLinkedin } from 'react-ionicons';
import { useLanguage } from '../utils/LanguageContext'; // Import the custom hook
import { FaGlobe } from 'react-icons/fa'; // Import the globe icon
import enContent from '../content/en.json'; // Import English content
import nlContent from '../content/nl.json'; // Import Dutch content

const Header = () => {
  const { language, setLanguage } = useLanguage(); // Use the language context
  const content = language === 'en' ? enContent : nlContent; // Determine content based on language
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setDropdownVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const header = document.querySelector("[data-header]");

    const onScroll = () => {
      if (window.scrollY > 100) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <header className="header" data-header>
      <HeaderToggle /> {/* Include HeaderToggle component */}
      <NavbarToggle /> {/* Include NavbarToggle component */}
      <div className='container'>

        <a href="/" className="logo">
          <img src='images/logo-dark.png' alt="HearBless Home" className="logo-light" style={{ width: 75, height: 75 }} />
          <img src='images/logo-dark.png' alt="HearBless Home" className="logo-dark" style={{ width: 75, height: 75 }} />
        </a>

        <nav className="navbar" data-navbar>
          <div className="navbar-top">
            <a href="/" className="logo">
              <img src='images/logo-dark.png' alt="HearBless Home" className="logo" style={{ width: 75, height: 75 }} />
            </a>

            <button className="nav-close-btn" aria-label="close menu" data-nav-toggler>
              <MenuOutline
                color={'#00000'}
                title={""}
                height="30px"
                width="30px"
                aria-hidden="true"
              />
            </button>
          </div>

          <ul className="navbar-list">
            <li>
              <Link to="/" className="navbar-link">Home</Link>
            </li>
            <li>
              <Link to="/about" className="navbar-link">About</Link>
            </li>
            <li>
              <Link to="/cases" className="navbar-link">Cases</Link>
            </li>
            <li>
              <Link to="/services" className="navbar-link">Services</Link>
            </li>
            <li className="navbar-link-contact">
              <Link to="/contact" className="navbar-link">Contact</Link>
            </li>
          </ul>

          <ul className="social-list">
            <li>
              <a href="/placeholder" className="social-link">
                <LogoInstagram
                  color={'#00000'}
                  title={"logo-instagram"}
                  height="50px"
                  width="50px"
                />
              </a>
            </li>
            <li>
              <a href="/placeholder" className="social-link">
                <LogoLinkedin
                  color={'#00000'}
                  title={"logo-linkedin"}
                  height="50px"
                  width="50px"
                />
              </a>
            </li>
          </ul>
        </nav>

        <div className="language-switcher" ref={dropdownRef}>
          <button onClick={toggleDropdown} className="globe-icon">
            <FaGlobe size={24} />
          </button>
          {dropdownVisible && (
            <div className="language-dropdown">
              <button onClick={() => handleLanguageSelect('en')}>English</button>
              <button onClick={() => handleLanguageSelect('nl')}>Dutch</button>
            </div>
          )}
        </div>

        <a href="/contact" className="btn btn-primary">Contact</a>

        <button className="nav-open-btn" aria-label="open menu" data-nav-toggler>
          <MenuOutline
            color={'#00000'}
            title={""}
            height="45px"
            width="45px"
            aria-hidden="true"
          />
        </button>

        <div className="overlay" data-nav-toggler data-overlay></div>
      </div>
    </header>
  );
};

export default Header;
