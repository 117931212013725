import React from 'react';
import { GitNetworkOutline, AnalyticsOutline, BarChartOutline, CloudyOutline } from 'react-ionicons'
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const Cases = () => {
  const { language } = useLanguage();
  const content = language === 'en' ? enContent : nlContent;

  return (
    <section className="section project" aria-labelledby="project-label">
      <div className="container">
      <h2 className="h2 section-title"><div dangerouslySetInnerHTML={{ __html: content.services.title }} /></h2>

        <div className="container">
        <div className="about-content">
            
        <div dangerouslySetInnerHTML={{ __html: content.services.description }} />
            
           

        </div>
      </div>
      </div>
      <div className="container">

          <p className="section-subtitle" id="service-label">--------------------------------</p>

          <ul className="grid-list">

            <li>
              <div className="service-card">

              <div className="card-icon">
                <BarChartOutline
                    color={'#00000'} 
                    title={""}
                    height="25px"
                    width="25px"
                    aria-hidden="true"
                    />
                </div>

                <h3 className="h4 card-title">Data Analytics</h3>

                <p className="card-text">
                <div dangerouslySetInnerHTML={{ __html: content.dataAnalytics }} />
                </p>

                <a href="#" className="btn-text">
                  <span className="span">Learn More</span>

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

            

            <li>
              <div className="service-card">
              <div className="card-icon">
                <AnalyticsOutline
                    color={'#00000'} 
                    title={""}
                    height="25px"
                    width="25px"
                    aria-hidden="true"
                    />
                </div>

                <h3 className="h4 card-title">Forecasting</h3>

                <p className="card-text">
                <div dangerouslySetInnerHTML={{ __html: content.forecasting }} />
                </p>

                <a href="#" className="btn-text">
                  <span className="span">Learn More</span>

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

            <li>
              <div className="service-card">
              <div className="card-icon">
                <CloudyOutline
                    color={'#00000'} 
                    title={""}
                    height="30px"
                    width="30px"
                    aria-hidden="true"
                    />
                </div>

                <h3 className="h4 card-title">Cloud Computing</h3>

                <p className="card-text">
                <div dangerouslySetInnerHTML={{ __html: content.cloudComputing }} />
                </p>

                <a href="#" className="btn-text">
                  <span className="span">Learn More</span>

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

            <li>
              <div className="service-card">

                <div className="card-icon">
                <GitNetworkOutline
                    color={'#00000'} 
                    title={""}
                    height="25px"
                    width="25px"
                    aria-hidden="true"
                    />
                </div>

                <h3 className="h4 card-title">Data Science & <br />Machine Learning</h3>

                <p className="card-text">
                <div dangerouslySetInnerHTML={{ __html: content.dataScienceMachineLearing }} />
                </p>

                <a href="#" className="btn-text">
                  <span class="span">Learn More</span>

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

          </ul>

        </div>
    </section>
  );
};

export default Cases;