import React from 'react';
import { Link } from 'react-router-dom';

import '../css/styles.css'; // Import the CSS file

const ErrorPage = () => {
    return (
        <div className="section-container">
        <div style={errorPageStyle}>
            <h2>Whoopsie, this page does not exist</h2>
            <Link to="/" style={linkStyle}>Back to main page</Link>
        </div>
        </div>
    );
};

const errorPageStyle = {
    textAlign: 'center',
    marginTop: '100px',
};

const linkStyle = {
    color: 'blue',
    textDecoration: 'underline',
    fontSize: '18px',
};

export default ErrorPage;
