// src/utils/LanguageContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const LanguageContext = createContext();

// Create a provider component
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // Retrieve the language from localStorage or default to 'nl'
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || 'nl';
  });

  useEffect(() => {
    // Save the language to localStorage whenever it changes
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);
