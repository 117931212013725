import React from 'react';
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';


const HeroSection = () => {
    const { language } = useLanguage();
    const content = language === 'en' ? enContent : nlContent;

    return (
    <section className="section hero has-bg-image" aria-label="home" style={{ backgroundImage: "url(images/hero-bg.png)", backgroundSize: "cover", minheight: "100vh" }}>

    <div className="container">
        <div className="hero-content">

        <h1 className="h1 hero-title" jsonKey="heroTitle">{content.heroSection.heroTitle}</h1>

        <p className="hero-text" jsonKey="heroText">
        <div dangerouslySetInnerHTML={{__html: content.heroSection.heroText}} />
        </p>

        <div className="btn-wrapper">
            <a href="/services" className="btn btn-primary">Explore Now</a>
            <a href="/contact" className="btn btn-outline">Contact</a>
        </div>
        </div>

        <div className="hero-content">

        <h1 className="h2" jsonKey="websiteConstructieTitle"><div dangerouslySetInnerHTML={{__html: content.heroSection.websiteConstructieTitle}} /></h1>

        <p className="hero-websiteconstruction" jsonKey="websiteConstructieText"><div dangerouslySetInnerHTML={{__html: content.heroSection.websiteConstructieText}} />     


        </p>
        </div>


    </div>

    </section>
    );
};


export default HeroSection; 

