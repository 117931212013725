import React from 'react';
import { useLanguage } from '../../utils/LanguageContext'; // Import the custom hook
import enContent from './content/en.json';
import nlContent from './content/nl.json';

const AboutMe = () => {
  const { language } = useLanguage();
  const content = language === 'en' ? enContent : nlContent;

  return (
    <section className="section about has-bg-image" aria-label="about-label" style={{ backgroundImage: "url(images/stats-bg.png)", backgroundSize: "cover", minheight: "100vh" }}>
       
      <div className="container">
        <figure className="about-banner">
          <img src="images/stats-bg.png" width="800" height="580" loading="lazy" alt="about banner"
            className="w-100" />
        </figure>

        <div className="about-content">
          <h2 className="h2 section-title">
          <div dangerouslySetInnerHTML={{ __html: content.aboutMe.title }} />
          </h2>
          <div className="about-content">
            <div dangerouslySetInnerHTML={{ __html: content.aboutMe.description }} />
            </div>

        </div>
      </div>
    </section>
  );
};

export default AboutMe;
